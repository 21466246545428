<template>
  <div class="newbieDetailContainer">
    <van-nav-bar
      :title="title"
      left-arrow
      @click-left="onClickLeft"
      :safe-area-inset-top="true"
      :border="false"
      :fixed="true"
      :placeholder="true"
    />
    <div class="newbieDetailContant" v-html="content"></div>
  </div>
</template>

<script>
export default {
  name: "NewbieDetail",
  data() {
    return {
      title: "",
      content: "",
    };
  },
  mounted() {
    this.title = this.$route.params.title;
    this.content = this.$route.params.content;
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.newbieDetailContainer {
  overflow: scroll;
  .newbieDetailContant {
    width: 336px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #777777;
    line-height: 20px;
    margin: 0 auto;
  }
}
::v-deep .van-nav-bar .van-icon {
  color: black;
  font-size: 16px;
}
</style>
